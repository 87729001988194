import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    ERP Solutions
                                </Link>
                            </h3>
                            <p>
                            Managing all the core supply chain, manufacturing, services, financial and other processes of an organization.
                            </p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Logistics Platform
                                </Link>
                            </h3>

                            <p>
                                We modernize global logistics. Find everything you need to optimize your supply chain on our platform.
                            </p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    eCommerce Integrations
                                </Link>
                            </h3> 

                            <p>Scale your e-commerce business with various integration solutions. Amazon, Shopify or B2B on your way.</p>
                            
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Research
                                </Link>
                            </h3>

                            <p>Objectively measure IT performance and create reports that celebrate IT success. Understand business needs and use data to prioritize a clear IT roadmap.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Analytics
                                </Link>
                            </h3>

                            <p>Advancements in analytics technology are creating new opportunities for you to capitalize on your data. Modern analytics are predictive, self-learning, and adaptive to help you uncover hidden data patterns.</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Technology
                                </Link>
                            </h3>

                            <p>Development of applications and apps that take advantage of all that current technology has to offer. We uses the different architectures, services and capabilities available to maximise the benefits.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne